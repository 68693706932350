
  html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
  }

  @font-face {
    font-family: 'HRSD';
    font-style: normal;
    font-weight: 400; /* Regular weight */
    src: url('../fonts/HRSD-Regular.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'HRSD';
    font-style: normal;
    font-weight: 700; /* Bold weight */
    src: url('../fonts/HRSD-Bold.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'HRSD';
    font-style: normal;
    font-weight: 300; /* Light weight */
    src: url('../fonts/HRSD-Light.ttf') format('truetype');
  }
  

  
  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .content {
    flex: 1; 
  }